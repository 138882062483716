@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@layer base {
  /* https://tailwindcss.com/docs/adding-base-styles */
}

@layer components {
  /*  branding colors */
  .bg-header {
    @apply bg-white;
  }

  
  /* Your new custom class for images */
  .custom-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  /* Standard Components */
  .btn-base {
    @apply block px-3 py-2 rounded-md text-base md:text-sm font-bold active:outline-none focus:outline-none;
  }

  .btn-nav {
    @apply btn-base hover:bg-amber-600 hover:text-gray-50;
  }

  .btn-nav-active {
    @apply btn-nav bg-amber-600 text-gray-50 hover:bg-amber-800 hover:text-gray-50;
  }

  .btn-subnav {
    @apply btn-nav text-gray-400 hover:bg-amber-600 dark:hover:bg-amber-200 dark:hover:text-gray-800;
  }

  .btn-subnav-active {
    @apply btn-nav-active dark:bg-amber-400 dark:text-gray-100 dark:hover:bg-amber-400 dark:hover:text-gray-100;
  }

  .btn-action {
    @apply btn-base shadow-sm font-bold text-sm text-gray-100 bg-amber-400 hover:bg-amber-800 hover:text-gray-100  dark:hover:bg-amber-200 dark:hover:text-gray-800;
  }

  .btn-cancel {
    @apply btn-base shadow-sm font-bold text-sm text-gray-100 bg-coral-400 hover:bg-coral-600 hover:text-gray-100;
  }

  .input-base {
    @apply block w-full mt-1 rounded-md text-sm border-gray-300 placeholder-gray-300 focus:ring-amber-500 focus:border-amber-500;
  }
}
.post-image {
  max-width: 100%;
  max-height: 300px; /* Adjust as per your design preference */
  width: auto;
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto; /* Center the image */
}


@layer utilities {
  /* https://tailwindcss.com/docs/adding-new-utilities */
}
